import React, {Fragment, useEffect, useState} from 'react'
import Popup from "reactjs-popup";
import {Dialog} from '@headlessui/react'

const CommandStructure = ({data}) => {

    return (
        <div>
            <Command {...data}/>
            <ColorModeChecker />
        </div>
    )
}

const checkDark = () => {
    const activeClasses = document.querySelector("body").classList

    if (activeClasses.contains("xstyled-color-mode-dark") && !activeClasses.contains("dark")) {
        document.querySelector("body").classList.add("dark")
    } else if (activeClasses.contains("xstyled-color-mode-light") && activeClasses.contains("dark")) {
        document.querySelector("body").classList.remove("dark")
    }
}

const ColorModeChecker = () => {
    useEffect(() => {
        const tm = setInterval(() => {
            checkDark()
        }, 100);

        return () => clearTimeout(tm)
    }, []);

    return null
}

const Command = ({command, desc, sub, argus = "", premium, cooldown, screenshot, toplevel}) => {
    // 0 is nothing
    const [index, setIndex] = useState(0)

    return (
        <article className="space-y-16 w-full">
            <div className="flex gap-x-6 xl:gap-x-10 w-full">
                {/* Adds a gray stripe on the left for sub commands */}
                {!toplevel && (
                    <div className="bg-line xl:h-16 w-[6px] rounded-full"/>
                )}

                <div className="w-full space-y-8">
                    <div className="space-y-8 mt-3">
                        <div>
                            <div
                                className="flex flex-col xl:flex-row xl:items-center space-y-6 xl:space-y-0 justify-between w-full">
                                <h1 className="text-black dark:text-[#fefeff] my-0 text-[28px] ">
                                    <button onClick={() => navigator.clipboard.writeText(command + " " + argus)}
                                            className="font-bold border-none focus:outline-none background-transparent">{command}</button>
                                    <span className="text-[#979aa1]"> {argus}</span></h1>

                                {/* Tags with popup */}
                                {(cooldown || premium || screenshot) && (
                                    <div className="space-x-6 flex items-center">
                                        {cooldown && (
                                            <Button text={cooldown} label="Cooldown"
                                                    color="bg-[#252525] text-[#D3D3D3]"/>
                                        )}
                                        {premium && (
                                            <Button text={premium} label="premium"
                                                    color="bg-[#00EEA3] text-[#1E5649]"/>
                                        )}
                                        {screenshot && (
                                                <Camera screenshotSrc={screenshot}/>
                                        )}
                                    </div>
                                )}
                            </div>
                            {/* Supports HTML code. If authors aren't trusted, please remove this since it can open the site up to injection attacks. */}
                            {desc && <h2 className="text-black dark:text-[#9ea1a8] font-medium text-2xl mt-12"
                                         dangerouslySetInnerHTML={{__html: desc}}/>}
                        </div>

                    </div>

                    {/* Sub commands toggle button are listed here */}
                    {sub && (
                        <div className="gap-x-6 gap-y-6  flex flex-wrap">
                            {sub?.map((s, localIndex) => {
                                const realLocalIndex = localIndex+1

                                return (
                                    <button onClick={() => {
                                        if ( index === realLocalIndex) {
                                            setIndex(0)
                                        } else {
                                            setIndex(realLocalIndex)
                                        }
                                    }} key={s.name}
                                            className={`py-2 px-4 xl:py-4 xl:px-6 bg-[#252525] border-[2.5px] border-border focus:border-[#959fa4] rounded-lg text-2xl ${index === realLocalIndex ? "text-white border-[#959fa4]" : "text-[#CFCFCF]"} space-x-2`}>
                                        <span>{s.name}</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                             className={`inline transform ${index === realLocalIndex ? "rotate-180" : "rotate-0"}`}>
                                            <path d="M12 19.5V5.5" stroke="#CFCFCF" strokeWidth="2" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M5 12.5L12 5.5L19 12.5" stroke="#CFCFCF" strokeWidth="2"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </button>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>

            {/* The sub command, refers to the component itself (*/}
            {sub && index !== 0 && sub[index-1].commands.map(c => <Fragment key={c.command + c.argus}>
                    <div className="space-x-6 xl:space-x-16 flex space-y-3 w-full">
                        <Command {...c}/>
                    </div>
                </Fragment>
            )}
        </article>
    )
}

// Can be embedded separately and will provide spacing
const Line = () => (
    <div className="border-line border my-10"/>
)

const Button = ({label, text, color}) => {

    return (
        <Popup trigger={<button
            className={`uppercase py-[6px] px-4 font-bold rounded-full ${color}`}>{label}</button>}
               position="bottom center">
            <div className="bg-[#252525] px-4 py-2 rounded mt-2 shadow text-white">{text}</div>
        </Popup>
    )
}

const Camera = ({screenshotSrc}) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            <button onClick={() => setIsOpen(!isOpen)} aria-label="Open screenshot in a popup">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                     xmlns="http://www.w3.org/2000/svg" className="text-black dark:text-white">
                    <g opacity="0.8">
                        <path
                            d="M30.6666 25.3333C30.6666 26.0406 30.3857 26.7189 29.8856 27.219C29.3855 27.719 28.7072 28 28 28H3.99998C3.29274 28 2.61446 27.719 2.11436 27.219C1.61426 26.7189 1.33331 26.0406 1.33331 25.3333V10.6667C1.33331 9.95942 1.61426 9.28115 2.11436 8.78105C2.61446 8.28095 3.29274 8 3.99998 8H9.33331L12 4H20L22.6666 8H28C28.7072 8 29.3855 8.28095 29.8856 8.78105C30.3857 9.28115 30.6666 9.95942 30.6666 10.6667V25.3333Z"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path
                            d="M16 22.6667C18.9455 22.6667 21.3334 20.2789 21.3334 17.3333C21.3334 14.3878 18.9455 12 16 12C13.0545 12 10.6667 14.3878 10.6667 17.3333C10.6667 20.2789 13.0545 22.6667 16 22.6667Z"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </g>
                </svg>
            </button>

            {/* See https://headlessui.dev/react/dialog for more info */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}
                    className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center"
            >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>

                <img src={screenshotSrc} alt="Command in action" className="z-20"/>

                <button onClick={() => setIsOpen(false)}
                        className="z-20 absolute top-4 right-4 bg-[#252525] py-2 px-4 rounded-lg text-white">Close
                </button>
            </Dialog>
        </div>
    )
}

export {CommandStructure, Line}