import { theme as baseTheme} from 'smooth-doc/src/theme'

export const theme = {
    ...baseTheme,
    fonts: {
        ...baseTheme.fonts,
        base: "Inter, sans-serif",
    },
    colors: {
        ...baseTheme.colors,
        modes: {
            ...baseTheme.colors.modes,
            dark: {
                ...baseTheme.colors.modes.dark,
                'background': '#15151d',
            },
        },
    },
}